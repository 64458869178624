import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';
import { MDCMenu } from '@material/menu';
import { MDCMenuSurfaceFoundation } from '@material/menu-surface';

if (document.querySelector('.mdc-list.nty-list-settings-backup')) {
  const backupListEls = Array.from(document.querySelectorAll('.nty-list-settings-backup'));
  backupListEls.forEach((el) => {
    const backupList = new MDCList(el);
    backupList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}

if (document.querySelector('.mdc-menu.nty-menu-backup')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-backup'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-backup-toggler');
    toggle.addEventListener('click', () => {
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 8,
        right: 0,
        bottom: 0,
        left: totalWidth - 186,
      });
      menu.open = !menu.open;
    });
  });
}
