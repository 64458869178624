import { MDCIconButtonToggle } from '@material/icon-button';

if (document.querySelector('.nty-container-note-private')) {
  const iconToggle = new MDCIconButtonToggle(
    document.querySelector('.nty-note-readability-toggler'),
  );

  if (localStorage.getItem('noteFullWidth') === 'true') {
    document
      .querySelector('.nty-container-note-private')
      .classList.add('nty-container-note-private--reading');
    iconToggle.on = true;
  } else {
    document
      .querySelector('.nty-container-note-private')
      .classList.remove('nty-container-note-private--reading');
    iconToggle.on = false;
  }

  iconToggle.listen('MDCIconButtonToggle:change', (e) => {
    if (e.detail.isOn === true) {
      localStorage.setItem('noteFullWidth', 'true');
      document
        .querySelector('.nty-container-note-private')
        .classList.add('nty-container-note-private--reading');
    } else {
      localStorage.removeItem('noteFullWidth');
      document
        .querySelector('.nty-container-note-private')
        .classList.remove('nty-container-note-private--reading');
    }
  });
}
