import { MDCTextField } from '@material/textfield';

if (document.getElementById('sign-in-form')) {
  const form = document.getElementById('sign-in-form');
  const signinFieldEls = Array.from(document.querySelectorAll('.nty-tf-sign-in'));

  signinFieldEls.forEach((el) => {
    MDCTextField.attachTo(el);
  });

  form.addEventListener('ajax:success', (event) => {
    const xhr = event.detail[2];
    const redir = JSON.parse(xhr.responseText).redirect;
    window.location.assign(redir);
  });

  form.addEventListener('ajax:error', (event) => {
    const xhr = event.detail[2];
    const err = JSON.parse(xhr.responseText).error;

    // Error for authentication
    if (Object.prototype.hasOwnProperty.call(err, 'auth')) {
      const emailParent = document.getElementById('session_email').parentElement;
      const passwordParent = document.getElementById('session_password').parentElement;
      const emailTextField = new MDCTextField(emailParent);
      const passwordTextField = new MDCTextField(passwordParent);
      emailTextField.valid = false;
      passwordTextField.valid = false;
      [passwordTextField.helperTextContent] = err.auth;
    }
  });
}
