// MDC Web
import { MDCRipple } from '@material/ripple';
// Font Awesome
import './font-awesome';
//Rails UJS
import Rails from '@rails/ujs';

Rails.start();

// MDC Web Button ripple
const buttonEls = Array.from(document.querySelectorAll('.mdc-button'));
buttonEls.forEach((el) => {
  MDCRipple.attachTo(el);
});

// MDC Web Icon Button ripple
const iconBtnEls = Array.from(document.querySelectorAll('.mdc-icon-button'));
iconBtnEls.forEach((el) => {
  const iconButtonRipple = new MDCRipple(el);
  iconButtonRipple.unbounded = true;
});
