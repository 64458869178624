import { MDCTextField } from '@material/textfield';

if (document.getElementById('password-reset-request-form')) {
  const form = document.getElementById('password-reset-request-form');
  const resetFieldEls = Array.from(document.querySelectorAll('.nty-tf-password-reset-request'));

  resetFieldEls.forEach((el) => {
    MDCTextField.attachTo(el);
  });

  form.addEventListener('ajax:success', (event) => {
    const xhr = event.detail[2];
    const redir = JSON.parse(xhr.responseText).redirect;
    window.location.assign(redir);
  });

  form.addEventListener('ajax:error', (event) => {
    const xhr = event.detail[2];
    const err = JSON.parse(xhr.responseText).error;

    // Error for account's email
    if (Object.prototype.hasOwnProperty.call(err, 'email')) {
      const emailParent = document.getElementById('password_reset_email').parentElement;
      const emailTextField = new MDCTextField(emailParent);
      emailTextField.valid = false;
      [emailTextField.helperTextContent] = err.email;
    }
  });
}
