import { MDCMenu } from '@material/menu';
import { MDCMenuSurfaceFoundation } from '@material/menu-surface';

if (document.querySelector('.mdc-menu.nty-menu-note')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-note'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-note-toggler');
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 0,
        right: 0,
        bottom: 0,
        left: totalWidth - 184,
      });
      menu.open = !menu.open;
    });
  });
}
