import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

if (document.querySelector('.mdc-list.nty-list-search')) {
  const searchListEls = Array.from(document.querySelectorAll('.nty-list-search'));
  searchListEls.forEach((el) => {
    const searchList = new MDCList(el);
    searchList.listElements.map((listItemEl) => new MDCRipple(listItemEl));

    searchList.listen('MDCList:action', (event) => {
      const listItemIndex = event.detail.index;
      const listItem = searchList.listElements[listItemIndex];
      const listItemPath = listItem.dataset.path;
      window.location.assign(listItemPath);
    });
  });
}
