import { MDCTextField } from '@material/textfield';

if (document.getElementById('sign-up-form')) {
  const form = document.getElementById('sign-up-form');
  const signupFieldEls = Array.from(document.querySelectorAll('.nty-tf-sign-up'));

  signupFieldEls.forEach((el) => {
    MDCTextField.attachTo(el);
  });

  form.addEventListener('ajax:success', (event) => {
    const xhr = event.detail[2];
    const redir = JSON.parse(xhr.responseText).redirect;
    window.location.assign(redir);
  });

  form.addEventListener('ajax:error', (event) => {
    const xhr = event.detail[2];
    const err = JSON.parse(xhr.responseText).error;

    // Error for account's email
    if (Object.prototype.hasOwnProperty.call(err, 'email')) {
      const emailParent = document.getElementById('account_email').parentElement;
      const emailTextField = new MDCTextField(emailParent);
      emailTextField.valid = false;
      [emailTextField.helperTextContent] = err.email;
    }

    // Error for account's password
    if (Object.prototype.hasOwnProperty.call(err, 'password')) {
      const passwordParent = document.getElementById('account_password').parentElement;
      const passwordTextField = new MDCTextField(passwordParent);
      passwordTextField.valid = false;
      [passwordTextField.helperTextContent] = err.password;
    }
  });
}
