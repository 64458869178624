import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

if (document.querySelector('.mdc-list.nty-list-settings-security')) {
  const securityListEls = Array.from(document.querySelectorAll('.nty-list-settings-security'));
  securityListEls.forEach((el) => {
    const securityList = new MDCList(el);
    securityList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}
