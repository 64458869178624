import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

if (document.querySelector('.mdc-list.nty-list-revisions')) {
  const revisionListEls = Array.from(document.querySelectorAll('.nty-list-revisions'));
  revisionListEls.forEach((el) => {
    const revisionList = new MDCList(el);
    revisionList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}
