import { MDCDialog } from '@material/dialog';
import { MDCTextField } from '@material/textfield';
import { MDCSwitch } from '@material/switch';

const dialog = new MDCDialog(document.getElementById('main-dialog'));

// Rails UJS
import Rails from '@rails/ujs';

// Note public url dialog
const shareDialogs = Array.from(document.querySelectorAll('.nty-button-public-url'));
shareDialogs.forEach((el) => {
  const shareDialog = el;

  shareDialog.onclick = (e) => {
    e.preventDefault();

    const notebookId = shareDialog.dataset.notebook;
    const noteId = shareDialog.dataset.note;
    const closeButtonText = shareDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/note/${noteId}/link`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-public-url-title').innerHTML;
        const content = res.getElementById('dialog-public-url-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfPubclicUrl = document.getElementById('public-url-textfield');
        MDCTextField.attachTo(tfPubclicUrl);
        const tfPubclicUrlWarning = document.getElementById('public-url-warning');
        const switchShare = new MDCSwitch(document.getElementById('note-sharing-switch'));

        if (switchShare.checked == true) {
          tfPubclicUrl.style.display = '';
          tfPubclicUrlWarning.style.display = '';
        } else {
          tfPubclicUrl.style.display = 'none';
          tfPubclicUrlWarning.style.display = 'none';
        }

        document.getElementById('note-sharing-switch').addEventListener('click', () => {
          if (switchShare.checked == true) {
            Rails.ajax({
              headers: {
                'X-CSRF-Token': document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute('content'),
              },
              url: `/notebook/${notebookId}/note/${noteId}/share`,
              type: 'POST',
              success: (res) => {
                document.getElementById('public-url-textfield-input').value = res.public_url;
                MDCTextField.attachTo(document.getElementById('public-url-textfield'));
              },
            });
            tfPubclicUrl.style.display = '';
            tfPubclicUrlWarning.style.display = '';
          } else if (switchShare.checked == false) {
            Rails.ajax({
              headers: {
                'X-CSRF-Token': document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute('content'),
              },
              url: `/notebook/${notebookId}/note/${noteId}/unshare`,
              type: 'DELETE',
              success: () => {
                document.getElementById('public-url-textfield-input').value = '';
              },
            });
            tfPubclicUrl.style.display = 'none';
            tfPubclicUrlWarning.style.display = 'none';
          }
        });
      },
    });

    document.getElementById('dialog-button-confirmation').style.display = 'none';

    dialog.open();

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
});

// Notebook edit dialog
const notebookEditDialogs = Array.from(document.querySelectorAll('.notebook-edit-button'));
notebookEditDialogs.forEach((el) => {
  const notebookEditDialog = el;

  notebookEditDialog.onclick = () => {
    const notebookId = notebookEditDialog.dataset.notebook;
    const confirmButtonText = notebookEditDialog.dataset.confirmation;
    const closeButtonText = notebookEditDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/edit`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-notebook-form-title').innerHTML;
        const content = res.getElementById('dialog-notebook-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfNotebookForm = document.getElementById('notebook-label-textfield');
        MDCTextField.attachTo(tfNotebookForm);

        // Notebook form
        const form = document.getElementById('notebook-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
        form.addEventListener('ajax:error', (event) => {
          const xhr = event.detail[2];
          const err = JSON.parse(xhr.responseText).error;
          if (Object.prototype.hasOwnProperty.call(err, 'label')) {
            const labelParent = document.getElementById('notebook_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.label;
          }
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('notebook-form')) {
        Rails.fire(document.getElementById('notebook-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
});

// Notebook create dialog
if (document.getElementById('notebook-create-button')) {
  const notebookCreateDialog = document.getElementById('notebook-create-button');

  notebookCreateDialog.onclick = () => {
    const confirmButtonText = notebookCreateDialog.dataset.confirmation;
    const closeButtonText = notebookCreateDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: '/settings/notebooks/new',
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-notebook-form-title').innerHTML;
        const content = res.getElementById('dialog-notebook-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfNotebookForm = document.getElementById('notebook-label-textfield');
        MDCTextField.attachTo(tfNotebookForm);

        // Notebook form
        const form = document.getElementById('notebook-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
        form.addEventListener('ajax:error', (event) => {
          const xhr = event.detail[2];
          const err = JSON.parse(xhr.responseText).error;
          if (Object.prototype.hasOwnProperty.call(err, 'label')) {
            const labelParent = document.getElementById('notebook_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.label;
          }
          if (Object.prototype.hasOwnProperty.call(err, 'account')) {
            const labelParent = document.getElementById('notebook_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.account;
          }
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('notebook-form')) {
        Rails.fire(document.getElementById('notebook-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
}

// Notebook destroy dialog
const notebookDestroyDialogs = Array.from(document.querySelectorAll('.notebook-destroy-button'));
notebookDestroyDialogs.forEach((el) => {
  const notebookDestroyDialog = el;

  notebookDestroyDialog.onclick = () => {
    const notebookId = notebookDestroyDialog.dataset.notebook;
    const confirmButtonText = notebookDestroyDialog.dataset.confirmation;
    const closeButtonText = notebookDestroyDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/delete`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-notebook-destroy-title').innerHTML;
        const content = res.getElementById('dialog-notebook-destroy-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfNotebookForm = document.getElementById('notebook-destroy-label-textfield');
        MDCTextField.attachTo(tfNotebookForm);

        // Notebook form
        const form = document.getElementById('notebook-destroy-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
        form.addEventListener('ajax:error', (event) => {
          const xhr = event.detail[2];
          const err = JSON.parse(xhr.responseText).error;
          if (Object.prototype.hasOwnProperty.call(err, 'label')) {
            const labelParent = document.getElementById('notebook_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.label;
          }
          if (Object.prototype.hasOwnProperty.call(err, 'account')) {
            const labelParent = document.getElementById('notebook_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.account;
          }
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('notebook-destroy-form')) {
        Rails.fire(document.getElementById('notebook-destroy-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
});

// Tag edit dialog
const tagEditDialogs = Array.from(document.querySelectorAll('.tag-edit-button'));
tagEditDialogs.forEach((el) => {
  const tagEditDialog = el;

  tagEditDialog.onclick = () => {
    const notebookId = tagEditDialog.dataset.notebook;
    const tagId = tagEditDialog.dataset.tag;
    const confirmButtonText = tagEditDialog.dataset.confirmation;
    const closeButtonText = tagEditDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/tag/${tagId}/edit`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-tag-form-title').innerHTML;
        const content = res.getElementById('dialog-tag-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfTagForm = document.getElementById('tag-label-textfield');
        MDCTextField.attachTo(tfTagForm);

        // Tag form
        const form = document.getElementById('tag-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
        form.addEventListener('ajax:error', (event) => {
          const xhr = event.detail[2];
          const err = JSON.parse(xhr.responseText).error;
          if (Object.prototype.hasOwnProperty.call(err, 'label')) {
            const labelParent = document.getElementById('tag_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.label;
          }
          if (Object.prototype.hasOwnProperty.call(err, 'depth')) {
            const depthParent = document.getElementById('tag-temp-depth').parentElement;
            const depthTextField = new MDCTextField(depthParent);
            depthTextField.valid = false;
            [depthTextField.helperTextContent] = err.depth;
          }
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('tag-form')) {
        Rails.fire(document.getElementById('tag-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
});

// Tag create dialog
if (document.getElementById('tag-create-button')) {
  const tagCreateDialog = document.getElementById('tag-create-button');

  tagCreateDialog.onclick = () => {
    const notebookId = tagCreateDialog.dataset.notebook;
    const confirmButtonText = tagCreateDialog.dataset.confirmation;
    const closeButtonText = tagCreateDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/tag/new`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-tag-form-title').innerHTML;
        const content = res.getElementById('dialog-tag-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;
        const tfTagForm = document.getElementById('tag-label-textfield');
        MDCTextField.attachTo(tfTagForm);

        // Tag form
        const form = document.getElementById('tag-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
        form.addEventListener('ajax:error', (event) => {
          const xhr = event.detail[2];
          const err = JSON.parse(xhr.responseText).error;
          if (Object.prototype.hasOwnProperty.call(err, 'label')) {
            const labelParent = document.getElementById('tag_label').parentElement;
            const labelTextField = new MDCTextField(labelParent);
            labelTextField.valid = false;
            [labelTextField.helperTextContent] = err.label;
          }
          if (Object.prototype.hasOwnProperty.call(err, 'depth')) {
            const depthParent = document.getElementById('tag-temp-depth').parentElement;
            const depthTextField = new MDCTextField(depthParent);
            depthTextField.valid = false;
            [depthTextField.helperTextContent] = err.depth;
          }
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('tag-form')) {
        Rails.fire(document.getElementById('tag-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
}

// Notebook select dialog for public note clone
if (document.getElementById('copy-public-note-button')) {
  const notebookSelectDialog = document.getElementById('copy-public-note-button');

  notebookSelectDialog.onclick = () => {
    const noteSharingHandle = notebookSelectDialog.dataset.note;
    const confirmButtonText = notebookSelectDialog.dataset.confirmation;
    const closeButtonText = notebookSelectDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/s/${noteSharingHandle}/save`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-notebook-select-form-title').innerHTML;
        const content = res.getElementById('dialog-notebook-select-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;

        // Notebook select form
        const form = document.getElementById('notebook-select-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('notebook-select-form')) {
        Rails.fire(document.getElementById('notebook-select-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
}

// Trash purge dialog
if (document.getElementById('trash-purge-button')) {
  const trashPurge = document.getElementById('trash-purge-button');

  trashPurge.onclick = () => {
    const notebookId = trashPurge.dataset.notebook;
    const confirmButtonText = trashPurge.dataset.confirmation;
    const closeButtonText = trashPurge.dataset.close;
    const dialogTitle = trashPurge.dataset.title;
    const dialogContent = trashPurge.dataset.content;

    document.getElementById('main-dialog-title').innerHTML = dialogTitle;
    document.getElementById('main-dialog-content').innerHTML = dialogContent;
    document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
    document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      Rails.ajax({
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        url: `/notebook/${notebookId}/trash/purge`,
        type: 'DELETE',
        success: (res) => {
          window.location.assign(res.redirect);
        },
      });
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
}

// Note move dialog
const noteMoveDialogs = Array.from(document.querySelectorAll('.move-note-link'));
noteMoveDialogs.forEach((el) => {
  const noteMoveDialog = el;

  noteMoveDialog.onclick = () => {
    const noteId = noteMoveDialog.dataset.note;
    const notebookId = noteMoveDialog.dataset.notebook;
    const confirmButtonText = noteMoveDialog.dataset.confirmation;
    const closeButtonText = noteMoveDialog.dataset.close;

    Rails.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      url: `/notebook/${notebookId}/note/${noteId}/move`,
      type: 'GET',
      success: (res) => {
        const title = res.getElementById('dialog-note-move-form-title').innerHTML;
        const content = res.getElementById('dialog-note-move-form-content').innerHTML;
        document.getElementById('main-dialog-title').innerHTML = title;
        document.getElementById('main-dialog-content').innerHTML = content;
        document.getElementById('dialog-button-confirmation-text').innerHTML = confirmButtonText;
        document.getElementById('dialog-button-close-text').innerHTML = closeButtonText;

        // Notebook select form
        const form = document.getElementById('note-move-form');
        form.addEventListener('ajax:success', (event) => {
          const xhr = event.detail[2];
          const redir = JSON.parse(xhr.responseText).redirect;
          window.location.assign(redir);
        });
      },
    });

    dialog.open();

    document.getElementById('dialog-button-confirmation').addEventListener('click', () => {
      if (document.getElementById('note-move-form')) {
        Rails.fire(document.getElementById('note-move-form'), 'submit');
      }
    });

    dialog.listen('MDCDialog:closed', () => {
      document.getElementById('main-dialog-title').innerHTML = '';
      document.getElementById('main-dialog-content').innerHTML = '';
      document.getElementById('dialog-button-confirmation-text').innerHTML = '';
      document.getElementById('dialog-button-close-text').innerHTML = '';
    });
  };
});
