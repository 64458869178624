// Font Awesone
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

// Configuration
// https://fontawesome.com/how-to-use/javascript-api/setup/configuration
config.keepOriginalSource = false;

// Icons
// https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core
import {
  faAngleDown,
  faAngleRight,
  faBars,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCompressAlt,
  faCopy,
  faDesktop,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExpandAlt,
  faFileDownload,
  faFileExport,
  faFolder,
  faFolderOpen,
  faHashtag,
  faHistory,
  faInbox,
  faLock,
  faMobileAlt,
  faSignOutAlt,
  faSort,
  faSync,
  faTabletAlt,
  faTag,
  faThumbtack,
  faTrash,
  faTrashAlt,
  faTrashRestore,
  faUsers,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';

import { faGoogle, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';

library.add(
  faAngleDown,
  faAngleRight,
  faBars,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCompressAlt,
  faCopy,
  faDesktop,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExpandAlt,
  faFileDownload,
  faFileExport,
  faFolder,
  faFolderOpen,
  faGoogle,
  faGoogleDrive,
  faHashtag,
  faHistory,
  faInbox,
  faLock,
  faMobileAlt,
  faSignOutAlt,
  faSort,
  faSync,
  faTabletAlt,
  faTag,
  faThumbtack,
  faTrash,
  faTrashAlt,
  faTrashRestore,
  faUsers,
  faUserSlash,
);

dom.watch();
