import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

if (document.querySelector('.mdc-list.nty-list-trash')) {
  const noteListEls = Array.from(document.querySelectorAll('.nty-list-trash'));
  noteListEls.forEach((el) => {
    const noteList = new MDCList(el);
    noteList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}
