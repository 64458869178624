import { MDCTabBar } from '@material/tab-bar';

if (document.querySelector('.mdc-tab-bar.nty-tab-bar-office')) {
  const tabBar = new MDCTabBar(document.querySelector('.nty-tab-bar-office'));

  tabBar.listen('MDCTab:interacted', (e) => {
    if (e.detail.tabId == 'tab-office-overview') {
      window.location.assign('/office');
    } else if (e.detail.tabId == 'tab-office-jobs') {
      window.location.assign('/office/jobs');
    }
  });
}
