import { MDCTextField } from '@material/textfield';
import { MDCTabBar } from '@material/tab-bar';

// CodeMirror
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/gfm/gfm';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/continuelist';

// Tagify
import Tagify from '@yaireo/tagify/dist/tagify.esm.js';

// Rails UJS
import Rails from '@rails/ujs';

// Tag list with Tagify
if (document.getElementById('note_tag_list')) {
  const tagInput = document.getElementById('note_tag_list');
  const notebookId = tagInput.dataset.notebook;

  // Tagify tags input
  const tagify = new Tagify(tagInput, {
    addTagOnBlur: true,
    autoComplete: {
      enabled: true,
      rightKey: true,
    },
    backspace: 'edit',
    delimiters: ',',
    duplicates: false,
    editTags: 1,
    dropdown: {
      accentedSearch: true,
      closeOnSelect: true,
      enabled: 1,
      fuzzySearch: true,
      highlightFirst: true,
      maxItems: 10,
    },
    skipInvalid: true,
  });

  // Fetch list of existing tags
  Rails.ajax({
    url: `/notebook/${notebookId}/tags.json`,
    type: 'GET',
    data: '',
    success: (res) => {
      tagify.settings.whitelist = res;
    },
  });

  // Format tags values for backend
  const noteForm = document.getElementById('note-form');
  noteForm.onsubmit = function () {
    const tagsArray = tagify.value;
    let tagsValuesArray = [];

    tagsValuesArray = tagsArray.map((item) => item['value']);

    document.getElementById('note_tag_list').value = tagsValuesArray;
  };
}

// Note form
if (document.getElementById('note-form')) {
  const form = document.getElementById('note-form');
  const noteFieldEls = Array.from(document.querySelectorAll('.nty-tf-note'));

  noteFieldEls.forEach((el) => {
    MDCTextField.attachTo(el);
  });

  form.addEventListener('ajax:success', (event) => {
    const xhr = event.detail[2];
    const redir = JSON.parse(xhr.responseText).redirect;
    window.location.assign(redir);
  });

  form.addEventListener('ajax:error', (event) => {
    const xhr = event.detail[2];
    const err = JSON.parse(xhr.responseText).error;

    // Error for note's title
    if (Object.prototype.hasOwnProperty.call(err, 'title')) {
      const titleParent = document.getElementById('note_title').parentElement;
      const titleTextField = new MDCTextField(titleParent);
      titleTextField.valid = false;
      [titleTextField.helperTextContent] = err.title;
    }

    // Error for note's tags
    if (Object.prototype.hasOwnProperty.call(err, 'tags')) {
      const tagsParent = document.getElementById('note-temp-tags').parentElement;
      const tagsTextField = new MDCTextField(tagsParent);
      tagsTextField.valid = false;
      [tagsTextField.helperTextContent] = err.tags;
    }
  });
}

// Setup CodeMirror for content
if (document.getElementById('note_content')) {
  const editor = CodeMirror.fromTextArea(document.getElementById('note_content'), {
    mode: {
      name: 'gfm',
      gitHubSpice: false,
      taskLists: true,
      strikethrough: true,
      emoji: false,
    },
    theme: 'eclipse',
    keyMap: 'sublime',
    lineWrapping: true,
    maxHighlightLength: 7500,
    viewportMargin: Infinity,
    // Addons
    autoCloseBrackets: true,
    extraKeys: { Enter: 'newlineAndIndentContinueMarkdownList' },
  });

  const tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar.nty-tab-bar-note'));

  tabBar.listen('MDCTab:interacted', (e) => {
    if (e.detail.tabId == 'tab-write-note') {
      document.getElementById('note-preview').style.display = 'none';
      document.getElementById('note-form').style.display = 'block';
    } else if (e.detail.tabId == 'tab-preview-note') {
      document.getElementById('note-preview').innerHTML = '';
      document.getElementById('note-form').style.display = 'none';
      document.getElementById('note-preview').style.display = 'block';
      const contentForPreview = `content=${encodeURIComponent(editor.getValue())}`;
      const notebookId = document.getElementById('tab-preview-note').dataset.notebook;
      Rails.ajax({
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        url: `/notebook/${notebookId}/note/preview`,
        type: 'POST',
        data: contentForPreview,
        success: (res) => {
          const serializedResponse = new XMLSerializer().serializeToString(res);
          document.getElementById('note-preview').innerHTML = serializedResponse;
        },
      });
    }
  });
}
