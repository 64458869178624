import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';
import { MDCMenu } from '@material/menu';
import { MDCMenuSurfaceFoundation } from '@material/menu-surface';

// Rails UJS
import Rails from '@rails/ujs';

if (document.querySelector('.nty-select-preferences')) {
  const selectArray = Array.from(document.querySelectorAll('.nty-select-preferences'));
  selectArray.forEach((el) => {
    el.onchange = () => {
      // https://github.com/rails/rails/issues/29546
      Rails.fire(document.querySelector('[data-preferences-note-sort-form]'), 'submit');
    };
  });
}

if (document.querySelector('.mdc-list.nty-list-settings-account')) {
  const securityListEls = Array.from(document.querySelectorAll('.nty-list-settings-account'));
  securityListEls.forEach((el) => {
    const securityList = new MDCList(el);
    securityList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}

if (document.querySelector('.mdc-menu.nty-menu-settings-email')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-settings-email'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-settings-email-toggler');
    toggle.addEventListener('click', () => {
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 8,
        right: 0,
        bottom: 0,
        left: totalWidth - 148,
      });
      menu.open = !menu.open;
    });
  });
}

if (document.querySelector('.mdc-menu.nty-menu-settings-password')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-settings-password'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-settings-password-toggler');
    toggle.addEventListener('click', () => {
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 8,
        right: 0,
        bottom: 0,
        left: totalWidth - 148,
      });
      menu.open = !menu.open;
    });
  });
}

if (document.querySelector('.mdc-menu.nty-menu-settings-google')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-settings-google'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-settings-google-toggler');
    toggle.addEventListener('click', () => {
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 8,
        right: 0,
        bottom: 0,
        left: totalWidth - 148,
      });
      menu.open = !menu.open;
    });
  });
}
