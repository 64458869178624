import { MDCTextField } from '@material/textfield';

if (document.getElementById('account-deregister-form')) {
  const form = document.getElementById('account-deregister-form');
  const deregisterFieldEls = Array.from(document.querySelectorAll('.nty-tf-settings-deregister'));

  deregisterFieldEls.forEach((el) => {
    MDCTextField.attachTo(el);
  });

  form.addEventListener('ajax:success', (event) => {
    const xhr = event.detail[2];
    const redir = JSON.parse(xhr.responseText).redirect;
    window.location.assign(redir);
  });

  form.addEventListener('ajax:error', (event) => {
    const xhr = event.detail[2];
    const err = JSON.parse(xhr.responseText).error;

    // Error for account's current password
    if (Object.prototype.hasOwnProperty.call(err, 'current_password')) {
      const currentPwdParent = document.getElementById('account_current_password').parentElement;
      const currentPwdTextField = new MDCTextField(currentPwdParent);
      currentPwdTextField.valid = false;
      [currentPwdTextField.helperTextContent] = err.current_password;
    }
  });
}
