import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';
import { MDCMenu } from '@material/menu';
import { MDCMenuSurfaceFoundation } from '@material/menu-surface';

if (document.querySelector('.mdc-list.nty-list-tags')) {
  const tagListEls = Array.from(document.querySelectorAll('.nty-list-tags'));
  tagListEls.forEach((el) => {
    const tagList = new MDCList(el);
    tagList.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
}

if (document.querySelector('.mdc-menu.nty-menu-tag')) {
  const menuEls = Array.from(document.querySelectorAll('.mdc-menu.nty-menu-tag'));
  menuEls.forEach((el) => {
    const menu = new MDCMenu(el);
    const toggle = el.parentElement.querySelector('.nty-menu-tag-toggler');
    toggle.addEventListener('click', () => {
      menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
      const totalWidth = el.parentElement.offsetWidth;
      menu.setAnchorMargin({
        top: 8,
        right: 0,
        bottom: 0,
        left: totalWidth - 148,
      });
      menu.open = !menu.open;
    });
  });
}
