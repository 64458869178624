import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCDrawer } from '@material/drawer';
import { MDCMenu } from '@material/menu';
import { MDCMenuSurfaceFoundation } from '@material/menu-surface';
import { MDCTextField } from '@material/textfield';

// Drawer
if (document.querySelector('.mdc-drawer')) {
  const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
  const topBar = MDCTopAppBar.attachTo(document.querySelector('.nty-layout-sidebar-top-app-bar'));
  topBar.setScrollTarget(document.getElementById('main-content'));

  if (localStorage.getItem('drawer') === 'true') {
    drawer.open = true;
  }

  topBar.listen('MDCTopAppBar:nav', () => {
    drawer.open = !drawer.open;
    drawer.listen('MDCDrawer:closed', () => {
      localStorage.removeItem('drawer');
    });
    drawer.listen('MDCDrawer:opened', () => {
      localStorage.setItem('drawer', 'true');
    });
  });
}

// Search form
if (document.querySelector('.nty-tf-drawer-search')) {
  MDCTextField.attachTo(document.querySelector('.nty-tf-drawer-search'));
}

// Account menu
function showAccountMenu(menu) {
  menu.setAnchorCorner(MDCMenuSurfaceFoundation.Corner.TOP_START);
  menu.setAnchorMargin({
    top: 4,
    right: 0,
    bottom: 0,
    left: 4,
  });
  menu.open = !menu.open;
}

if (document.querySelector('.mdc-menu.nty-menu-account')) {
  const menuAccount = document.querySelector('.mdc-menu.nty-menu-account');
  const menu = new MDCMenu(menuAccount);
  const toggle = menuAccount.parentElement.querySelector('.nty-menu-account-toggler');
  toggle.addEventListener('click', () => {
    showAccountMenu(menu);
  });
  toggle.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      showAccountMenu(menu);
    }
  });
}
