import { MDCTabBar } from '@material/tab-bar';

if (document.querySelector('.mdc-tab-bar.nty-tab-bar-account-settings')) {
  const tabBar = new MDCTabBar(document.querySelector('.nty-tab-bar-account-settings'));

  tabBar.listen('MDCTab:interacted', (e) => {
    if (e.detail.tabId == 'tab-settings-account') {
      window.location.assign('/settings/account');
    } else if (e.detail.tabId == 'tab-settings-notebooks') {
      window.location.assign('/settings/notebooks');
    } else if (e.detail.tabId == 'tab-settings-backup') {
      window.location.assign('/settings/backup');
    } else if (e.detail.tabId == 'tab-settings-security') {
      window.location.assign('/settings/security');
    }
  });
}
